<template>
    <div class="container">
        <b-row class="mt-40">
            <b-col
                cols="12"
                class="not-found"
            >
                <img
                    v-for="index in 3"
                    :key="index"
                    :class="'question-' + index"
                    src="/images/question.svg"
                    :alt="$t('page.notFound.heading')"
                >

                <h1>404</h1>
                <h4>{{ $t('page.notFound.heading') }}</h4>
                <h5>{{ $t('page.notFound.infoText') }}</h5>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    export default {
        name: 'NotFound',
        metaInfo() {
            return {
                title: '404',
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
    };
</script>
<style scoped lang="scss">
.not-found {
  min-height: calc(100vh - 72px - 392px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  @include media-breakpoint-down(sm) {
    min-height: 50vh;
  }
  img {
    position: absolute;
    opacity: 0.036;
    width: 25%;
    &.question-1 {
      left: 0;
    }
    &.question-2 {
      top: -40px;
      @include media-breakpoint-down(md) {
        top: -30%;
      }
      @include media-breakpoint-down(sm) {
        top: -15%;
      }
    }
    &.question-3 {
      bottom: -40px;
      right: 0;
      @include media-breakpoint-down(md) {
        bottom: -30%;
      }
      @include media-breakpoint-down(sm) {
        bottom: -15%;
      }
    }
  }
  h1 {
    letter-spacing: 1px;
    color: $primary;
    font-size: 180px;
    margin-bottom: 0;
    @include media-breakpoint-between(sm, md) {
      font-size: 120px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 90px;
    }
  }
  h4 {
    text-align: center;
    font-size: 48px;
    color: $secondary;
    @include media-breakpoint-between(sm, md) {
      font-size: 40px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 32px;
    }
  }
  h5 {
    text-align: center;
    margin-top: 16px;
  }
}
</style>
